import React from "react";
import { AiOutlineUser } from "react-icons/ai";

export const NoAvatar: React.FC = () => (
  <div
    className="d-flex align-items-center justify-content-center border rounded-circle text-grey"
    style={{ height: "56px", width: "56px" }}>
    <AiOutlineUser size="40" />
  </div>
);
