import React from "react";
import { BuildingInfoSummaryFragmentFragment } from "../../generated/gateway-client";
import { Card } from "../Card";
import { CardImage } from "../CardImage";

type BuildingInfoPost = BuildingInfoSummaryFragmentFragment;

interface Props {
  className?: string;
  buildingInfoPost: BuildingInfoPost;
  focusable?: boolean;
  metric?: {
    ns: string;
    nx: number;
  };
}

export const BuildingInfoCard: React.FC<Props> = ({ metric, className, buildingInfoPost: post, focusable }) => {
  const metricExt = metric == null ? "" : `?ns=${metric.ns}&nx=${metric.nx}`;

  return (
    <Card
      img={<CardImage url={post.featuredImage.url} />}
      href="/info/[uuid]"
      as={`/info/${post.uuid}${metricExt}`}
      focusable={focusable}
      title={post.title}
      className={`building-info-card ${className}`}
    >
      <div className="excerpt">{post.excerpt}</div>
    </Card>
  );
}
