import { Theme, Viewer } from "@equiem/web-ng-lib";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { useQuickLinksQuery } from "../../generated/gateway-client";
import { Greeting } from "./Greeting";
import { QuickLinks } from "./QuickLinks";
import { ViewerAvatar } from "./ViewerAvatar";
import { WelcomeBlockSkeleton } from "./WelcomeBlockSkeleton";

const boxHeight = 112;

interface Props {
  position: number;
  className?: string;
  onLoading?: (args: { loading: boolean; hasContent: boolean }) => void;
}

export const WelcomeBlock: React.FC<Props> = ({
  position,
  className = "",
  onLoading = (_args) => undefined,
}) => {
  const viewer = useContext(Viewer);
  const divRef = useRef<HTMLDivElement>(null);
  const [showExpandBtn, setShowExpandBtn] = useState(false);
  const [expand, setExpand] = useState(false);
  const { breakpoints } = useContext(Theme);
  const { loading, data } = useQuickLinksQuery();
  const quicklinks = data?.quickLinks ?? [];

  const toggle = useCallback(() => {
    setExpand((e) => !e);
  }, []);

  useEffect(() => {
    const height = divRef.current?.clientHeight;
    if (height == null || height < boxHeight) {
      return;
    }

    setShowExpandBtn(true);
  }, [divRef.current?.clientHeight]);

  useEffect(() => {
    if (!loading) {
      onLoading({ loading, hasContent: quicklinks.length > 0 });
    }
  }, [quicklinks, loading]);

  if (loading) {
    return <WelcomeBlockSkeleton className={className} />;
  }

  if (quicklinks.length === 0 || viewer?.profile == null) {
    return null;
  }

  return (
    <div className={className}>
      <div className="welcome-block border rounded">
        <Container className="p-0" fluid>
          <Row>
            <Col md="4" lg="3" className="pb-2 pb-md-0">
              <div className="d-flex align-items-center">
                <ViewerAvatar className="mr-3 d-none d-md-block" />
                <span className="h4 greeting mb-0">
                  <Greeting />, {viewer!.profile!.firstName}
                </span>
              </div>
            </Col>
            <Col>
              <div className={`wb-quicklinks${expand ? " expand" : ""}`}>
                <div className="d-flex flex-wrap" ref={divRef}>
                  <QuickLinks links={quicklinks} position={position} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {
        showExpandBtn ? (
          <div className="toggle-expand-cont">
            <a onClick={toggle} className="border rounded-circle d-flex align-items-center justify-content-center">
              {expand ? <BsChevronUp size="20px" className="m-0" /> : <BsChevronDown size="20px" className="m-0" />}
            </a>
          </div>
        ) : null
      }
      <style jsx>{`
        .toggle-expand-cont {
          position: relative;
          height: 20px;
        }
        .toggle-expand-cont a {
          background: #fff;
          cursor: pointer;
          position: absolute;
          top: -20px;
          left: calc(50% - 20px);
          width: 40px;
          height: 40px;
          z-index: 1;
        }
        .toggle-expand-cont a:hover {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        }
        .welcome-block {
          padding: 16px 16px 0;
        }
        .wb-quicklinks {
          transition: max-height .35s ease-in-out;
          overflow: hidden;
          max-height: ${boxHeight + 24}px;
          padding-bottom: 24px;
        }
        .wb-quicklinks.expand {
          max-height: 1000px;
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .greeting {
            font-size: 18px;
            line-height: 24px;
          }
        }
        @media screen and (min-width: ${breakpoints.md}px) {
          .welcome-block {
            padding: 24px 24px 0;
          }
          .wb-quicklinks {
            padding-bottom: 32px;
          }
        }
        @media screen and (min-width: ${breakpoints.lg}px) {
          .wb-quicklinks {
            max-height: ${boxHeight}px;
          }
        }
      `}</style>
    </div>
  );
};
