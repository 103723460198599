import React from "react";
import { NewsSummaryFragmentFragment } from "../../generated/gateway-client";
import { Card } from "../Card";
import { CardImage } from "../CardImage";
import { InteractionCounts } from "../InteractionCounts";

type NewsPost = NewsSummaryFragmentFragment;

interface Props {
  className?: string;
  newsPost: NewsPost;
  focusable?: boolean;
  metric?: {
    ns: string;
    nx: number;
  };
}

export const NewsCard: React.FC<Props> = ({ metric, className, newsPost, focusable }) => {
  const metricExt = metric == null ? "" : `?ns=${metric.ns}&nx=${metric.nx}`;

  return (
    <Card
      img={<CardImage url={newsPost.featuredImage.url} />}
      href="/news/[uuid]"
      as={`/news/${newsPost.uuid}${metricExt}`}
      title={newsPost.title}
      focusable={focusable}
      className={`news-card ${className}`}
    >
      <div className="excerpt">{newsPost.excerpt}</div>
      <div className="counts pt-3" style={{ marginTop: "auto" }}>
        <InteractionCounts content={newsPost} />
      </div>
    </Card>
  );
}
