import { useEffect, useMemo } from "react";
import { ContentUnion, useCuratedFeedQuery } from "../../generated/gateway-client";
import { SkeletonCards } from "../SkeletonCards";
import { ContentCardSwiper } from "./ContentCardSwiper";

interface Props {
  className?: string;
  metric?: { ns: string; nx: number };
  title: string;
  show: boolean;
  widgetUuid: string;
  pageSize?: number;
  onLoading?: (args: { loading: boolean; hasContent: boolean }) => void;
}

export const CuratedContentCardSwiper: React.FC<Props> = ({
  className,
  metric,
  title,
  show,
  widgetUuid,
  pageSize = 12,
  onLoading = (_args) => undefined,
}) => {
  const { called, loading, data, fetchMore } = useCuratedFeedQuery({
    variables: {
      uuid: widgetUuid,
      first: pageSize,
    },
    notifyOnNetworkStatusChange: true,
  });
  const moreCuratedContent = useMemo(() => async () => {
    const pageInfo = data?.curatedFeed.pageInfo;
    if (pageInfo?.hasNextPage !== true || pageInfo.endCursor == null) {
      return;
    }

    await fetchMore({
      variables: { first: pageSize, after: pageInfo.endCursor },
    });
  }, [data?.curatedFeed.pageInfo]);
  const edges = data?.curatedFeed.edges?.filter((edge) => "node" in edge && edge.node != null) ?? [];
  const hasContent = edges.length > 0;

  useEffect(() => {
    if (called && !loading) {
      onLoading({ loading, hasContent });
    }
    // !! do not add edges to the useEffect dependencies below, it'd cause an infinite rendering loop !!
  }, [hasContent, called, loading]);

  return show && edges.length > 0 ? (
    <ContentCardSwiper
      loading={loading}
      className={className}
      metric={metric}
      title={title}
      edges={edges as ContentUnion[]}
      reachEnd={moreCuratedContent}
    />
  ) : show && loading ? <SkeletonCards /> : null;
};
