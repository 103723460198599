import { useMemo } from "react";
import { useTrendingContentQuery as useQuery} from "../generated/gateway-client";

export const useTrendingContentQuery = (pageSize = 12) => {
  const { called, loading, data, fetchMore } = useQuery({
    variables: { first: pageSize },
    notifyOnNetworkStatusChange: true,
  });

  return {
    data,
    loading,
    called,
    pageNum: Math.floor((data?.trendingContentFeed.edges ?? []).length / pageSize),
    fetchMore: useMemo(() => async () => {
      const pageInfo = data?.trendingContentFeed.pageInfo;
      if (pageInfo?.hasNextPage !== true || pageInfo.endCursor == null) {
        return;
      }

      await fetchMore({
        variables: { first: pageSize, after: pageInfo.endCursor },
      });
    }, [data?.trendingContentFeed.pageInfo]),
  };
};
