import React, { useEffect } from "react";
import { usePromotedHomeDiscountsQuery } from "../../generated/gateway-client";
import { DiscountCard } from "../DiscountCard";

interface Props {
  className?: string;
  onLoading?: (args: { loading: boolean; hasContent: boolean }) => void;
}

export const PromotedDiscountBlock: React.FC<Props> = ({ className, onLoading = (_args) => undefined }) => {
  const { loading, data } = usePromotedHomeDiscountsQuery();
  const discounts = data?.promotedHomeDiscounts;

  useEffect(() => {
    if (!loading) {
      onLoading({ loading, hasContent: discounts != null && discounts.length > 0 });
    }
  }, [discounts, loading]);

  if (loading || discounts == null || discounts.length === 0) {
    return null;
  }

  return (
    <>
      {discounts.map((discount) => <DiscountCard key={discount.code} className={className} discount={discount} />)}
    </>
  );
};
