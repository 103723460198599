import { useTranslation } from "@equiem/web-ng-lib";
import { Viewer } from "@equiem/web-ng-lib";
import Link from "next/link";
import React from "react";
import { NoAvatar } from "./NoAvatar";

interface Props {
  className?: string;
}

export const ViewerAvatar: React.FC<Props> = ({ className = "" }) => {
  const { t } = useTranslation();

  return (
    <span className={`viewer-avatar ${className}`}>
      <Viewer.Consumer>
        {(viewer) =>
          viewer == null ? null : (
            <Link href="/profile" passHref>
              <a
                className={`rounded-circle d-flex justify-content-center align-items-center ${
                  viewer.loading ? "" : "loaded"
                }`}
                style={{
                  backgroundImage:
                    viewer.profile?.avatar == null
                      ? undefined
                      : `url("${viewer.profile.avatar}")`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  width: "56px",
                  height: "56px",
                  verticalAlign: "middle",
                  display: "inline-flex",
                  textDecoration: "none",
                }}
                title={
                  (viewer.loading
                    ? t("main.signingIn")
                    : t("main.signingInAs", { email: viewer.profile?.email ?? "" }))
                }
              >
                {viewer.loading || viewer.profile?.avatar == null ? (
                  <NoAvatar />
                ) : null}
              </a>
            </Link>
          )
        }
      </Viewer.Consumer>
    </span>
  );
};
