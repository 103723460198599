import { formatters, useTranslation } from "@equiem/web-ng-lib";
import React from "react";
import { DateTime } from "../../lib/DateTime";

const formatDay = (
  date1: number,
  date2: number,
  lng: string,
  timezone: string,
) => {
  const startDate = DateTime.fromMillis(date1, { zone: timezone });
  const endDate = DateTime.fromMillis(date2, { zone: timezone });
  const sameDate = startDate.hasSame(endDate, "date");

  const d1 = formatters.dateshort(date1, lng, { timezone });
  const d2 = formatters.dateshort(date2, lng, { timezone });

  return sameDate ? (
    <div className="day">
      <span>{d1}</span>
    </div>
  ) : (
    <>
      <div className="day">
        <span>{d1}</span>
        <span className="mx-2">-</span>
      </div>
      <div className="day">
        <span>{d2}</span>
      </div>
    </>
  );
};

interface Props {
  startDate: number;
  endDate: number;
  timezone: string;
}

export const EventDate: React.FC<Props> = ({
  startDate,
  endDate,
  timezone,
}) => {
  const { i18n } = useTranslation();

  return (
    <>
      <span className="event-container">
        {
          <>
            <div>
              {formatDay(startDate, endDate, i18n.language, timezone)}
            </div>
          </>
        }
      </span>
      <style jsx>{`
        .event-container {
          display: inline-block;
        }
        .day {
          display: block;
          font-size: 1.25rem;
        }
      `}</style>
    </>
  );
};
