import React, { useEffect, useMemo } from "react";
import { useNewsContentFeedQuery } from "../../generated/gateway-client";
import { CardSwiper } from "../CardSwiper";
import { NewsCard } from "../news/NewsCard";

interface Props {
  className?: string;
  title: string;
  show: boolean;
  onLoading?: (args: { loading: boolean; hasContent: boolean }) => void;
  metric?: { ns: string; nx: number };
  pageSize?: number;
}

export const NewsContentCardSwiper: React.FC<Props> = ({
  className,
  title,
  show,
  onLoading = (_args) => undefined,
  metric,
  pageSize = 12,
}) => {
  const { called, loading, data, fetchMore } = useNewsContentFeedQuery({
    notifyOnNetworkStatusChange: true,
    variables: { first: pageSize },
  });

  const moreNews = useMemo(() => async () => {
      const pageInfo = data?.newsContentFeed.pageInfo;
      if (pageInfo?.hasNextPage !== true || pageInfo.endCursor == null) {
        return;
      }

      await fetchMore({
        variables: { first: pageSize, after: pageInfo.endCursor },
      });
    }, [data?.newsContentFeed.pageInfo]);

  const content = data?.newsContentFeed.edges ?? [];

  const cards = useMemo(
    () => content.flatMap((edge, i) => {
      if (edge.__typename === "NewsEdge" && edge.node != null) {
        return [<NewsCard key={i} className="swiper-slide" newsPost={edge.node} metric={metric} />];
      }

      return [];
    }),
    [content],
  );

  useEffect(() => {
    if (called && !loading) {
      onLoading({ loading, hasContent: cards.length > 0 });
    }
  }, [cards, called, loading]);

  return !show ? null : (
    <CardSwiper
      title={title}
      reachEnd={moreNews}
      breakpoints={{
        xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 4,
      }}
      loading={loading}
      className={className}
    >{{ cards }}</CardSwiper>
  );
};
