import { SkeletonLine } from "@equiem/web-ng-lib";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NoAvatar } from "./NoAvatar";

interface Props {
  className?: string;
}

export const WelcomeBlockSkeleton: React.FC<Props> = ({ className = "" }) => (
  <div className={`border rounded welcome-block-skeleton p-3 py-4 ${className}`}>
    <Container fluid>
      <Row>
        <Col md="4" lg="3" className="pb-2 pb-md-0">
          <div className="d-flex align-items-center">
            <div className="d-none d-md-block mr-3">
              <NoAvatar />
            </div>
            <div>
              <SkeletonLine width="130px" height="20px" />
              <SkeletonLine width="100px" height="20px" />
            </div>
          </div>
        </Col>
        <Col>
          <SkeletonLine width="150px" height="35px" borderRadius="20px" className="mr-2 mb-2" />
          <SkeletonLine width="170px" height="35px" borderRadius="20px" className="mr-2 mb-2" />
          <SkeletonLine width="160px" height="35px" borderRadius="20px" className="mr-2 mb-2" />
          <SkeletonLine width="190px" height="35px" borderRadius="20px" className="mr-2 mb-2" />
        </Col>
      </Row>
    </Container>
  </div>
);
