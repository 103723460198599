import { useTranslation } from "@equiem/web-ng-lib";
import { Button, Site, stringNotEmpty, Theme } from "@equiem/web-ng-lib";
import { NextPage } from "next";
import Link from "next/link";
import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import { Textfit } from "react-textfit";

export const WelcomePage: NextPage = () => {
  const { t } = useTranslation();

  const { breakpoints } = useContext(Theme);

  return (
    <Site.Consumer>
      {(site) => (
        <div
          className="welcome-background text-light d-flex flex-column m-0 vh-100"
          style={{
            backgroundImage:
              site.loggedOutHome.heroImage != null
                ? `url("${site.loggedOutHome.heroImage}")`
                : "none",
            backgroundSize: "cover",
            backgroundColor:
              site.loggedOutHome.heroImage == null
                ? site.primaryColour
                : undefined,
            backgroundPosition: "center",
            padding: "2.5rem",
          }}
        >
          {site.logo != null ? (
            <div className="logo-container">
              <img src={site.logo} alt={site.name} />
            </div>
          ) : null}
          <div className="intro text-overlay-shadow d-flex flex-grow-1 justify-content-center align-items-center">
            <div className="text-center mw-100" style={{ width: "35rem" }}>
              {stringNotEmpty(site.loggedOutHome.heading) ? (
                // Do not remove site-name id. It's used in external automated tests.
                <h1 id="site-name" className="display-3 text-light">
                  <Textfit mode="multi">{site.loggedOutHome.heading}</Textfit>
                </h1>
              ) : null}
              {stringNotEmpty(site.loggedOutHome.subHeading) ? (
                <p className="lead">{site.loggedOutHome.subHeading}</p>
              ) : null}
              <Button
                // Do not remove login-button class name. It's used in external automated tests.
                className="m-3 py-2 px-4 login-button"
                variant="solid"
                rounded={false}
                href="/api/login"
              >
                {t("main.logIn")} <FaArrowRight />
              </Button>
              {site.registrationEnabled ? (
                <Button
                  className="m-3 py-2 px-4"
                  variant="solid"
                  rounded={false}
                  href="/api/login?screen_hint=signup"
                >
                  {t("main.register")} <FaArrowRight />
                </Button>
              ) : null}
            </div>
          </div>
          <Container fluid>
            <Row>
              <Col sm={4}>
                {stringNotEmpty(site.footerLogo) ? (
                  <div className="footer-logo">
                    <img src={site.footerLogo} />
                  </div>
                ) : null}
              </Col>
              <Col sm={4} style={{
                textAlign: "center",
                alignSelf: "center",
              }}>
                <Link href="/legal" passHref>
                  <a style={{ marginRight: "10px" }} className="text-light">{t("main.terms")}</a>
                </Link>|
                <Link href="/privacy" passHref>
                  <a style={{ marginLeft: "10px" }} className="text-light">{t("main.privacy")}</a>
                </Link>
              </Col>
              {/* Empty col to ensure the above 2 elements stay in left and middle elements */}
              <Col sm={4}></Col>
            </Row>

          </Container>
          <style jsx>{`
            .footer-logo img {
              max-width: 100px;
            }
            .intro :global(.btn) {
              font-size: 0.875rem;
              border: 1.5px solid #fff;
            }
            .intro :global(.btn:not(:hover)) {
              background: rgba(255, 255, 255, 0.3);
              color: #fff;
            }
            .intro :global(.btn:hover) {
              background: #fff;
              color: #000;
            }
            @media (max-width: ${breakpoints.sm}px) {
              .footer-logo {
                justify-content: center;
                display: flex;
              }
            }
          `}</style>
        </div>
      )}
    </Site.Consumer>
  );
};
