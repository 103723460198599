import { useTranslation } from "@equiem/web-ng-lib";
import { ProgressCircle, Theme, ThemeContext } from "@equiem/web-ng-lib";
import React, { createRef, useContext, useEffect, useState } from "react";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import Swiper from "swiper";
import { Navigation } from "swiper/modules";

interface Props {
  title: string;
  loading: boolean;
  breakpoints: {
    [key in keyof ThemeContext["breakpoints"]]: number;
  };
  reachEnd?: () => Promise<void>;
  children: {
    cards?: React.ReactElement[] | null;
    noneFound?: React.ReactNode;
  }
  noGutters?: boolean;
  className?: string;
  skeleton?: React.ReactElement | null;
}

Swiper.use([Navigation]);

export const CardSwiper: React.FC<Props> = ({
  title,
  loading,
  reachEnd,
  breakpoints,
  children,
  noGutters = false,
  className = "",
  skeleton,
}) => {
  const { t } = useTranslation();

  const [swiper, setSwiper] = useState<Swiper | undefined>();
  const container = createRef<HTMLDivElement>();
  const prevEl = createRef<HTMLAnchorElement>();
  const nextEl = createRef<HTMLAnchorElement>();
  const theme = useContext(Theme);

  const initOrUpdateSwiper = () => {
    const currentSwiper = swiper != null ? swiper : new Swiper(container.current!, {
      slidesPerView: 5,
      simulateTouch: false,
      watchSlidesProgress: true,
      spaceBetween: noGutters ? 0 : 10,
      breakpoints: {
        [theme.breakpoints.xl]: { slidesPerView: breakpoints.xl },
        [theme.breakpoints.lg]: { slidesPerView: breakpoints.lg },
        [theme.breakpoints.md]: { slidesPerView: breakpoints.md },
        [theme.breakpoints.sm]: { slidesPerView: breakpoints.sm },
        [theme.breakpoints.xs]: { slidesPerView: breakpoints.xs },
      },
      navigation: {
        nextEl: nextEl.current ?? undefined,
        prevEl: prevEl.current ?? undefined,
      },
    });

    if (swiper != null) {
      currentSwiper.update();
    }
    else {
      setSwiper(currentSwiper);
    }

    attachEventHandlers(currentSwiper);

    return currentSwiper;
  };

  const attachEventHandlers = (currentSwiper: Swiper) => {
    if (reachEnd != null) {
      currentSwiper.on("reachEnd", reachEnd);
    }
    currentSwiper.on("transitionStart", () => {
      currentSwiper.el.classList.remove("is-static")
    });
    currentSwiper.on("transitionEnd", () => {
      currentSwiper.el.classList.add("is-static")
    });
    currentSwiper.on("touchStart", () => {
      currentSwiper.el.classList.remove("is-static")
    });

    const updateTabindexes = () => {
      // tslint:disable-next-line: no-unsafe-any
      currentSwiper.el.querySelector(".swiper-slide:not(.swiper-slide-visible) a")?.setAttribute("tabindex", "-1");
      // tslint:disable-next-line: no-unsafe-any
      currentSwiper.el.querySelector(".swiper-slide.swiper-slide-visible a")?.setAttribute("tabindex", "0");
    };

    updateTabindexes();
    currentSwiper.on("slideChange", () => {
      updateTabindexes();
    });
  };

  const detatchEventHandlers = (currentSwiper: Swiper) => {
    if (reachEnd != null) {
      currentSwiper.off("reachEnd");
    }
    currentSwiper.off("slideChange");
    currentSwiper.off("transitionStart");
    currentSwiper.off("transitionEnd");
    currentSwiper.off("touchStart");
  };

  useEffect(() => () => {
    if (swiper != null) {
      swiper.destroy(true, true);
    }
  }, []);

  useEffect(() => {
    const currentSwiper = initOrUpdateSwiper();

    return () => {
      detatchEventHandlers(currentSwiper);
    }
  }, [children.cards]);

  const count = children.cards == null ? 0 : children.cards instanceof Array ? children.cards.length : 1;

  return (
    <div className={`text-dark card-swiper ${loading ? "loading": ""} ${className}`}>
      <div className="title-bar d-flex justify-content-between align-items-center pb-2">
        <h2 className="text-site">{title}</h2>
        <div className="prev-next text-site">
          {loading && count > 0 ? <ProgressCircle size="md" /> : null }
          <a ref={prevEl} tabIndex={0} className="prev rounded rounded-circle"><GoChevronLeft style={{ marginBottom: ".05em" }} /></a>
          <a ref={nextEl} tabIndex={0} className="next rounded rounded-circle"><GoChevronRight style={{ marginBottom: ".05em" }} /></a>
        </div>
      </div>
      { loading
        ? (count === 0 ? skeleton : null)
        : (count === 0 ? children.noneFound ?? <p className="text-muted">{t("main.none")}</p> : null) }
      <div ref={container} className="swiper is-static">
        <div className="swiper-wrapper d-flex align-items-stretch">
          {children.cards}
        </div>
      </div>
      <style jsx>{`
        .swiper,
        .swiper-wrapper {
          padding: 15px 0 20px 0;
          margin: -15px 0 -20px 0;
        }
        .is-static,
        .is-static .swiper-wrapper {
          padding-right: 15px;
          padding-left: 15px;
          margin-right: -15px;
          margin-left: -15px;
        }
        .swiper-wrapper :global(.swiper-slide) {
          height: auto;
        }
        .title-bar {
          line-height: 1;
        }
        .prev-next {
          font-size: 3rem;
          white-space: nowrap;
          user-select: none;
        }
        .prev-next a {
          outline: none;
        }
        .prev-next a:hover,
        .prev-next a:focus {
          cursor: pointer;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        }
        .prev-next a.swiper-button-disabled {
          opacity: 0.5;
          cursor: default;
          box-shadow: none;
        }
        .swiper-wrapper :global(a) {
          outline: none;
        }
        .is-static :global(.swiper-slide) {
          visibility: hidden;
        }
        .is-static :global(.swiper-slide-visible:not(.swiper-slide-prev)) {
          visibility: visible;
        }
        /* Swiper iceberg pattern */
        @media
          (max-width: ${theme.breakpoints.sm - 1}px),
          (hover: none) and (max-width: ${theme.breakpoints.md - 1}px) {
          .swiper,
          .swiper-wrapper,
          .is-static,
          .is-static .swiper-wrapper {
            padding-right: min(30%, 4rem);
            padding-left: 15px;
            margin-right: -15px;
            margin-left: -15px;
          }
          .is-static :global(.swiper-slide) {
            visibility: visible !important;
          }
        }
      `}</style>
    </div>
  );
};
