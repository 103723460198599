import { Site } from "@equiem/web-ng-lib";
import { NextPage } from "next";
import { useContext } from "react";
import { PlatformType, UiWidgetType, useHomeUiWidgetsV2Query } from "../../generated/gateway-client";
import { GeneralLayout } from "../layout/GeneralLayout";
import { SiteBanner } from "../SiteBanner";
import { HomePageWidgets } from "./HomePageWidgets";
import { WelcomeBlockSkeleton } from "./WelcomeBlockSkeleton";

// we must supply a list of supported widget types when requesting widgets from the api,
// supported meaning we know what to do to display it.
const widgetTypes = [
  UiWidgetType.Booking,
  UiWidgetType.Curated,
  UiWidgetType.Events,
  UiWidgetType.Featured,
  UiWidgetType.Forme,
  UiWidgetType.News,
  UiWidgetType.Popular,
  UiWidgetType.Quicklinks,
  UiWidgetType.Reorder,
  UiWidgetType.Transport,
  UiWidgetType.Trending,
  UiWidgetType.Discount,
];

export const HomePage: NextPage = () => {
  const site = useContext(Site);
  const data = useHomeUiWidgetsV2Query({ variables: { uuid: site.uuid, platform: PlatformType.Web, enabled: true, widgetTypes }});

  return (
    <GeneralLayout personalized prefix={
      <SiteBanner
        height="440px"
        showSiteName={site.loggedInHome.showSiteName}
      />
    }>
      { data.loading ? <WelcomeBlockSkeleton /> : <HomePageWidgets widgets={data.data?.destination.homeUIWidgetsV2 ?? []} /> }
    </GeneralLayout>
  );
}
