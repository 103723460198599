import { Session } from "@equiem/web-ng-lib";
import { NextPage } from "next";
import { HomePage } from "../components/homepage/HomePage";
import { WelcomePage } from "../components/homepage/WelcomePage";
import { withAppContexts } from "../contexts/withAppContexts";
import { PageViewPageType } from "../generated/gateway-client";

const Page: NextPage = () => (
  <Session.Consumer>
    { (s) => s.authenticated ? <HomePage /> : <WelcomePage /> }
  </Session.Consumer>
);

export { Page };

export default withAppContexts(Page, {
  requireAuth: false,
  skipMetrics: false,
  pageViewType: PageViewPageType.Home,
});
