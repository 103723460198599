import React, { useEffect } from "react";
import { ContentUnion, useForMeContentFeedQuery } from "../../generated/gateway-client";
import { ContentCardSwiper } from "./ContentCardSwiper";

interface Props {
  className?: string;
  metric?: { ns: string; nx: number };
  title: string;
  onLoading?: (args: { loading: boolean; hasContent: boolean }) => void;
  show: boolean;
}

export const ForMeContentCardSwiper: React.FC<Props> = ({
  className,
  metric,
  title,
  show,
  onLoading = (_args) => undefined,
}) => {
  const { called, loading, data } = useForMeContentFeedQuery({
    notifyOnNetworkStatusChange: true,
  });
  const edges = data?.forMeContentFeed.edges?.filter((edge) => "node" in edge && edge.node != null) ?? [];
  const hasContent = edges.length > 0;

  useEffect(() => {
    if (called && !loading) {
      onLoading({ loading, hasContent });
    }
    // !! do not add edges to the useEffect dependencies below, it'd cause an infinite rendering loop !!
  }, [hasContent, called, loading]);

  return show && edges.length > 0 ? (
    <ContentCardSwiper
      loading={loading}
      className={className}
      metric={metric}
      title={title}
      edges={edges as ContentUnion[]}
    />
  ) : null;
};
