import React, { useEffect, useMemo } from "react";
import { useTrendingContentQuery } from "../../hooks/useTrendingContentQuery";
import { CardSwiper } from "../CardSwiper";
import { EventCard } from "../events/EventCard";
import { NewsCard } from "../news/NewsCard";

interface Props {
  className?: string;
  title: string;
  onLoading?: (args: { loading: boolean; hasContent: boolean }) => void;
  show: boolean;
  metric?: { ns: string; nx: number };
}

export const TrendingContentSwiper: React.FC<Props> = ({
  className,
  title,
  show,
  metric,
  onLoading = (_args) => undefined,
}) => {
  const { called, loading, data, fetchMore } = useTrendingContentQuery();

  const cards = useMemo(
    () => (data?.trendingContentFeed.edges ?? []).flatMap((edge, i) => {
      switch (edge.__typename) {
        case "NewsEdge":
          return edge.node != null ? [<NewsCard key={i} className="swiper-slide" newsPost={edge.node} metric={metric} />] : [];
        case "EventOccurrenceEdge":
          return edge.node != null ? [<EventCard key={i} className="swiper-slide" occurrence={edge.node} metric={metric} />] : [];
      }

      return [];
    }),
    [data?.trendingContentFeed.edges],
  );

  useEffect(() => {
    if (called && !loading) {
      onLoading({ loading, hasContent: cards.length > 0 });
    }
  }, [cards, called, loading]);

  return !show ? null : (
    <CardSwiper
      title={title}
      reachEnd={fetchMore}
      breakpoints={{
        xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 4,
      }}
      loading={loading}
      className={className}
    >{{ cards }}</CardSwiper>
  );
};
