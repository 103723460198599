import { useTranslation } from "@equiem/web-ng-lib";
import React from "react";
import { DateTime } from "../../lib/DateTime";

export const Greeting: React.FC = () => {
  const { t } = useTranslation();

  const hour = +DateTime.today().toFormat("H");

  if (hour >= 5 && hour < 12) {
    return <span>{t("main.goodMorning")}</span>;
  }

  if (hour >= 12 && hour < 18) {
    return <span>{t("main.goodAfternoon")}</span>;
  }

  if (hour >= 18 && hour <= 23) {
    return <span>{t("main.goodEvening")}</span>;
  }

  return <span>{t("main.goodNight")}</span>;
};
