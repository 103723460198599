import { useTranslation } from "@equiem/web-ng-lib";
import { notNullOrUndefined } from "@equiem/web-ng-lib";
import { NextPage } from "next";
import { useCallback } from "react";
import { useState } from "react";
import { HomeUiWidgetsV2Query, UiWidgetType } from "../../generated/gateway-client";
import { ContentNotAvailable } from "../ContentNotAvailable";
import { SkeletonCards } from "../SkeletonCards";
import { CuratedContentCardSwiper } from "./CuratedContentCardSwiper";
import { FeaturedContentCardSwiper } from "./FeaturedContentCardSwiper";
import { ForMeContentCardSwiper } from "./ForMeContentCardSwiper";
import { NewsContentCardSwiper } from "./NewsContentCardSwiper";
import { PromotedDiscountBlock } from "./PromotedDiscountBlock";
import { TrendingContentSwiper } from "./TrendingContentSwiper";
import { UpcomingEvents } from "./UpcomingEvents";
import { WelcomeBlock } from "./WelcomeBlock";
import { truncateText } from "../../lib/truncateText";

interface Props {
  widgets: HomeUiWidgetsV2Query["destination"]["homeUIWidgetsV2"];
}

export const HomePageWidgets: NextPage<Props> = ({ widgets }) => {
  const { t } = useTranslation();

  const hasType = useCallback((type: UiWidgetType) => widgets.findIndex((w) => w.type === type) > -1, [widgets]);
  const [quicklinks, setQuicklinks] = useState({ loading: hasType(UiWidgetType.Quicklinks), hasContent: false });
  const [upcomingEvents, setUpcomingEvents] = useState({ loading: hasType(UiWidgetType.Events), hasContent: false });
  const [forMe, setForMe] = useState({ loading: hasType(UiWidgetType.Forme), hasContent: false });
  const [featured, setFeatured] = useState({ loading: hasType(UiWidgetType.Featured), hasContent: false });
  const [news, setNews] = useState({ loading: hasType(UiWidgetType.News), hasContent: false });
  const [discount, setDiscount] = useState({ loading: hasType(UiWidgetType.Discount), hasContent: false });
  const [trending, setTrending] = useState({ loading: hasType(UiWidgetType.Trending), hasContent: false });
  const [allCurated, setAllCurated] = useState(widgets.map((widget, index) => widget.type !== UiWidgetType.Curated ? null : ({
    index,
    loading: true,
    hasContent: false,
  })).filter(notNullOrUndefined));

  const anyLoading = [
    quicklinks.loading,
    upcomingEvents.loading,
    forMe.loading,
    featured.loading,
    discount.loading,
    news.loading,
    trending.loading,
    ...allCurated.map((feed) => feed.loading),
  ].includes(true);

  const anyContent = [
    quicklinks.hasContent,
    upcomingEvents.hasContent,
    forMe.hasContent,
    featured.hasContent,
    discount.hasContent,
    news.hasContent,
    trending.hasContent,
    ...allCurated.map((feed) => feed.hasContent),
  ].includes(true);

  return (
    <>
      {
        widgets.map(({ type, name, metricName, uuid }, index) => {
          const position = index + 1;
          switch (type) {
            case UiWidgetType.Quicklinks:
              return (
                <WelcomeBlock
                  key={`home-${index}`}
                  className="mb-4"
                  onLoading={setQuicklinks}
                  position={position}
                />
              );
            case UiWidgetType.Discount:
              return (
                <PromotedDiscountBlock
                  key={`home-${index}`}
                  className="mb-4"
                  onLoading={setDiscount}
                />
              );
            case UiWidgetType.Events:
              return (
                <UpcomingEvents
                  key={`home-${index}`}
                  show={!quicklinks.loading && upcomingEvents.hasContent}
                  className="mb-4"
                  onLoading={setUpcomingEvents}
                  metric={{ ns: metricName, nx: position}}
                />
              );
            case UiWidgetType.Forme:
              return (
                <ForMeContentCardSwiper
                  key={`home-${index}`}
                  title={t("main.forMe")}
                  show={!quicklinks.loading && forMe.hasContent}
                  className="mb-4"
                  onLoading={setForMe}
                  metric={{ ns: metricName, nx: position}}
                />
              );
            case UiWidgetType.Featured:
              return (
                <FeaturedContentCardSwiper
                  key={`home-${index}`}
                  title={t("main.featured")}
                  show={!quicklinks.loading && featured.hasContent}
                  className="mb-4"
                  onLoading={setFeatured}
                  metric={{ ns: metricName, nx: position}}
                />
              );
            case UiWidgetType.News:
              return (
                <NewsContentCardSwiper
                  key={`home-${index}`}
                  title={t("main.news")}
                  show={!quicklinks.loading &&  news.hasContent}
                  className="mb-4"
                  onLoading={setNews}
                  metric={{ ns: metricName, nx: position}}
                />
              );
            case UiWidgetType.Trending:
              return (
                <TrendingContentSwiper
                  key={`home-${index}`}
                  title={t("main.trending")}
                  show={!quicklinks.loading && trending.hasContent}
                  className="mb-4"
                  onLoading={setTrending}
                  metric={{ ns: metricName, nx: position}}
                />
              );
            case UiWidgetType.Curated:
              return (
                <CuratedContentCardSwiper
                  key={`home-${index}`}
                  metric={{ ns: metricName, nx: position}}
                  title={truncateText(name)}
                  show={!quicklinks.loading}
                  className="mb-4"
                  widgetUuid={uuid}
                  onLoading={({ loading, hasContent }) => {
                    setAllCurated(allCurated.map((feed) => feed.index !== index ? feed : ({
                      loading,
                      hasContent,
                      index,
                    })));
                  }}
                />
              );
            default:
              return null;
          }
        })
      }
      {anyLoading ? <SkeletonCards /> : null}
      {anyLoading || anyContent ? null : (
        <ContentNotAvailable
          title={t("main.contentComingSoon")}
          ctaReplacement={<></>}
        />
      )}
    </>
  );
}
