import { formatters, useTranslation } from "@equiem/web-ng-lib";
import { Site } from "@equiem/web-ng-lib";
import Link from "next/link";
import React, { useContext } from "react";
import { OccurrenceSummaryFragmentFragment } from "../../generated/gateway-client";
import { RsvpButton } from "../events/RsvpButton";
import { Clock } from "../icons/Clock";
import { Location } from "../icons/Location";
import { InteractionCounts } from "../InteractionCounts";
import { EventDate } from "./EventDate";

interface Props {
  className?: string;
  occurrence: OccurrenceSummaryFragmentFragment;
  focusable?: boolean;
  metric?: {
    ns: string;
    nx: number;
  };
}

export const EventCard: React.FC<Props> = ({ className, occurrence, focusable, metric }) => {
  const { i18n } = useTranslation();

  const site = useContext(Site);
  const headHeight = "7.6rem";
  const metricExt = metric == null ? "" : `?ns=${metric.ns}&nx=${metric.nx}`;

  return (
    <div className={`${className} root event-card active-shadow ${occurrence.rsvpedByMe ? "going" : "not-going"}`}>
      <Link href="/event/[uuid]" as={`/event/${occurrence.uuid}${metricExt}`} passHref>
        <a
          className="d-flex flex-column h-100"
          tabIndex={focusable === false ? -1 : 0}
        >
          <div
            className="d-flex bg-site justify-content-center align-items-center border border-site-contrast head text-site-contrast"
          >
            <h3 className="m-0 p-4 d-flex justify-content-center align-items-center">
              <EventDate
                startDate={occurrence.startDate}
                endDate={occurrence.endDate}
                timezone={site.timezone}
              />
            </h3>
          </div>
          <div
            className="body flex-grow-1 p-3 border d-flex flex-column text-dark"
          >
            <div className="title pb-3">{occurrence.title}</div>
            <div className="info">
              <div className="time d-flex">
                <span><Clock /></span>
                <span className="pl-1 icon-label">
                  {formatters.timeshort(occurrence.startDate, i18n.language, {
                    timezone: site.timezone,
                  })}
                  {" - "}
                  {formatters.timeshort(occurrence.endDate, i18n.language, {
                    timezone: site.timezone,
                  })}
                </span>
              </div>
              { occurrence.location == null ? null : (
                <div className="location d-flex">
                  <span><Location /></span>
                  <span className="pl-1 icon-label">{occurrence.location}</span>
                </div>
              )}
              <div className="counts pt-3">
                <InteractionCounts content={occurrence} />
              </div>
            </div>
          </div>
        </a>
      </Link>
      { !occurrence.isRsvpEnabled ? null : <RsvpButton {...occurrence} shadow="hover" focusable={focusable} />}
      <style jsx>{`
        .root {
          position: relative;
        }
        a {
          text-decoration: none;
        }
        .head {
          text-align: center;
          height: ${headHeight};
          padding-left: 1px;
          border-width: 1px 1px 1px 0 !important;
        }
        .body {
          text-align: left;
          font-size: 0.9375rem;
          line-height: 1.6;
          border-width: 0 1px 1px 0 !important;
        }
        .body > div {
          padding-left: 1px;
        }
        .root.swiper-slide-active .head {
          border-width: 1px 1px 1px 1px !important;
          padding-left: 0;
        }
        .root.swiper-slide-active .body {
          border-width: 0 1px 1px 1px !important;
        }
        .root.swiper-slide-active .body > div {
          padding-left: 0;
        }
        .info {
          margin-top: auto;
        }
        .title {
          font-size: 1.0625rem;
        }
        .time,.title {
          font-weight: 600;
        }
        .root :global(.btn-rsvp) {
          position: absolute;
          top: ${headHeight};
          right: 11px;
          transform: translate(0, -70%);
        }
      `}</style>
    </div>
  );
};
