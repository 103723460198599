import { useTranslation } from "@equiem/web-ng-lib";
import React, { useEffect, useMemo } from "react";
import { useEventSummaryQuery } from "../../hooks/useEventSummaryQuery";
import { CardSwiper } from "../CardSwiper";
import { EventCard } from "./EventCard";

interface Props {
  show: boolean;
  onLoading?: (args: { loading: boolean; hasContent: boolean }) => void;
  className?: string;
  metric?: { ns: string; nx: number };
}

export const UpcomingEvents: React.FC<Props> = ({
  className,
  show,
  onLoading = (_args) => undefined,
  metric,
}) => {
  const { t } = useTranslation();

  const { called, loading, data, occurrences, fetchMore } = useEventSummaryQuery();
  const cards = useMemo(
    () => occurrences.map((occurrence, i) => (
      <EventCard key={i} className="swiper-slide" occurrence={occurrence} metric={metric}></EventCard>
    )),
    [data?.upcomingEventsContentFeed.edges],
  );

  useEffect(() => {
    if (called && !loading) {
      onLoading({ loading, hasContent: cards.length > 0 });
    }
  }, [cards, called, loading]);

  return !show ? null : (
    <CardSwiper
      title={t("main.upcomingEvents")}
      reachEnd={fetchMore}
      breakpoints={{
        xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 5,
      }}
      loading={loading}
      className={className}
      noGutters
    >{{ cards }}</CardSwiper>
  );
};
