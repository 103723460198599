import { Site } from "@equiem/web-ng-lib";
import React, { useContext } from "react";
import { Textfit } from "react-textfit";

interface Props {
  height: string;
  image?: string | null;
  showSiteName?: boolean;
}

export const SiteBanner: React.FC<Props> = ({ image: imageInput, height, showSiteName = true }) => {
  const site = useContext(Site);
  const image = imageInput ?? site.loggedInHome.heroImage;

  return (
    <div
      className="site-banner d-flex flex-column justify-content-center align-items-center w-100 text-overlay-shadow"
      style={{
        backgroundColor: site.primaryColour,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height,
        maxHeight: "40vh",
        backgroundImage: image != null ? `url("${image}")` : undefined,
      }}
    >
      {showSiteName ? (
        <h1 className="display-3 text-light text-center w-100">
          <Textfit mode="multi">{site.name}</Textfit>
        </h1>
      ) : null}
    </div>
  );
}
