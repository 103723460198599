import { Session } from "@equiem/web-ng-lib";
import { Site, Theme } from "@equiem/web-ng-lib";
import React, { useContext } from "react";
import { LinkTrackType, QuickLinksQuery, useLinkTrackMetricMutation } from "../../generated/gateway-client";
import { QuickLink } from "../QuickLink";

interface Props {
  links: QuickLinksQuery["quickLinks"];
  position: number;
}

export const QuickLinks: React.FC<Props> = ({ position, links }) => {
  const session = useContext(Session);
  const theme = useContext(Theme);
  const site = useContext(Site);
  const [linkTrack] = useLinkTrackMetricMutation({
    client: session.authenticated && session.side === "client" ? session.authenticatedClient : undefined});

  const qlClick = (title: string) => async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();

    const url = e.currentTarget.getAttribute("href");
    if (url == null) {
      return;
    }

    const target = e.currentTarget.getAttribute("target");
    const isExternal = target != null;

    try {
      // Trigger mutation when the link is external.
      if (isExternal) {
        await linkTrack({
          variables: {
            input: {
              timestamp: new Date().getTime(),
              type: LinkTrackType.Quicklink,
              siteUuid: site.uuid,
              title,
              url,
              position,
            },
          },
        });
      }
    }
    finally {
      window.open(url, isExternal ? "_blank" : "_self");
    }

  };

  return (
    <>
      {
        links.map(({ uuid, url, title, iconUrlPrimaryColor }, i) => (
          <QuickLink
            uuid={uuid}
            className="h-qlinks"
            key={`wb-ql-${i}`}
            iconUrl={iconUrlPrimaryColor}
            onClick={qlClick(title)}
            url={url}
            title={title}
            appendInternalNsNx={true}
            position={position}
          />
        ))
      }
      <style jsx global>{`
        .h-qlinks {
          border: 2px solid ${site.primaryColour};
          border-radius: 20px;
          background: ${site.primaryColour};
          color: ${site.primaryContrastColour} !important;
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          padding: 4px 16px;
          display: block;
          margin: 0 8px 8px 0;
        }
        @media screen and (min-width: ${theme.breakpoints.lg}px) {
          .h-qlinks {
            padding: 8px 16px;
          }
        }
      `}</style>
    </>
  );
};
