import React, { useMemo } from "react";
import {
  ContentUnion,
  ProductBookableListingFragment,
  ProductPlainListingFragment,
  ProductSubscriptionListingFragment,
} from "../../generated/gateway-client";
import { BuildingInfoCard } from "../building-info/BuildingInfoCard";
import { CardSwiper } from "../CardSwiper";
import { EventCard } from "../events/EventCard";
import { NewsCard } from "../news/NewsCard";
import { ListingBookable } from "../store/products/ListingBookable";
import { ListingDeal } from "../store/products/ListingDeal";
import { ListingPlain } from "../store/products/ListingPlain";
import { ListingSubscription } from "../store/products/ListingSubscription";

interface Props {
  className?: string;
  metric?: { ns: string; nx: number };
  reachEnd?: () => Promise<void>;
  title: string;
  loading: boolean;
  edges: ContentUnion[];
}

export const ContentCardSwiper: React.FC<Props> = ({
  className,
  metric,
  reachEnd,
  title,
  loading,
  edges,
}) => {
  const cards = useMemo(
    () => edges.map((edge, i) => {
      if (null == edge.node) { return <></>; }
      switch (edge.__typename) {
        case "NewsEdge":
          return <NewsCard key={i} className="swiper-slide" newsPost={edge.node} metric={metric} />;
        case "EventOccurrenceEdge":
          return <EventCard key={i} className="swiper-slide" occurrence={edge.node} metric={metric} />;
        case "BuildingInfoEdge":
          return <BuildingInfoCard key={i} className="swiper-slide" buildingInfoPost={edge.node} metric={metric} />;
        case "PlainProductContentEdge":
          return <div key={i} className="swiper-slide"><ListingPlain product={edge.node as ProductPlainListingFragment} metric={metric} /></div>;
        case "SubscriptionProductContentEdge":
          return <div key={i} className="swiper-slide"><ListingSubscription product={edge.node as ProductSubscriptionListingFragment} metric={metric} /></div>;
        case "BookableProductContentEdge":
          return <div key={i} className="swiper-slide"><ListingBookable product={edge.node as ProductBookableListingFragment} metric={metric} /></div>;
        case "DealContentEdge":
          return <div key={i} className="swiper-slide"><ListingDeal product={edge.node} metric={metric} /></div>;
        default:
          return <></>;
      }
    }),
    [edges],
  );

  return (
    <CardSwiper
      title={title}
      reachEnd={reachEnd}
      breakpoints={{
        xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 4,
      }}
      loading={loading}
      className={className}
    >
      {{ cards }}
    </CardSwiper>
  );
};
