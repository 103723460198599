import { useMemo } from "react";
import { useUpcomingEventsContentFeedQuery } from "../generated/gateway-client";

export const useEventSummaryQuery = (pageSize = 12) => {
  const result = useUpcomingEventsContentFeedQuery({
    notifyOnNetworkStatusChange: true,
    variables: { first: pageSize },
  });

  return {
    ...result,
    pageNum: Math.floor((result.data?.upcomingEventsContentFeed.edges ?? []).length / pageSize),
    fetchMore: useMemo(() => async () => {
      const pageInfo = result?.data?.upcomingEventsContentFeed.pageInfo;
      if (pageInfo?.hasNextPage !== true || pageInfo.endCursor == null) {
        return;
      }

      await result.fetchMore({
        variables: { first: pageSize, after: pageInfo.endCursor },
      });
    }, [result.data?.upcomingEventsContentFeed.pageInfo]),
    occurrences: (result.data?.upcomingEventsContentFeed.edges ?? []).flatMap((e) => e.__typename === "EventOccurrenceEdge" && e.node != null ? [e.node] : []),
  };
};
